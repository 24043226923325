import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EventList = () => {
  const [events, setEvents] = useState([]); // Store events data
  const [searchQuery, setSearchQuery] = useState(''); // Store search input

  const apiUrl = 'https://brownskinbrunchin.app/wp-json/mec/v1.0'; // API URL
  const apiKey = 'kcKZkPDMKK8lxX8yfuQlFOdZYQcnyW9TEOtxYr0N'; // API Key

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Fetch data from the API with authorization
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        setEvents(response.data); // Set events data
      } catch (error) {
        console.error('Error fetching events:', error.message);
      }
    };
    fetchEvents();
  }, []);

  // Filter events based on the search query
  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <h1>Brown Skin Brunchin' Events</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search events by title..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '20px',
          width: '100%',
          maxWidth: '400px',
          fontSize: '16px',
        }}
      />

      {/* Display Filtered Events */}
      {filteredEvents.length > 0 ? (
        filteredEvents.map((event) => (
          <div key={event.ID} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
            <h2>{event.title}</h2>
            <p>Date: {event.mec_start_date || 'Date not available'}</p>
          </div>
        ))
      ) : (
        <p>No events found.</p>
      )}
    </div>
  );
};

export default EventList;
